<template>
  <div class="posts-list">
    <div class="item">
      <div class="item-header">
        <div class="item-header_title">
          <span>作品列表</span>
          <el-popover popper-class="black-popover" trigger="hover" content="所选周期内的作品，数据每天更新" width="180">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>

          <div class="refresh" @click="refresh">
            <i class="el-icon-refresh-right"></i>
            <span>刷新</span>
          </div>
        </div>

        <div class="item-header_operation">
          <el-button icon="el-icon-download" @click="exportData">导出数据</el-button>
        </div>
      </div>
    </div>
    <div class="container-box">
      <el-table
        :data="itemList"
        :header-cell-style="{ fontWeight: '400', color: 'rgba(37,38,50,0.6)', background: ' #F2F2F4' }"
        :cell-style="{ fontSize: '14px', color: ' #252632' }"
        size="mini"
      >
        <!-- <el-table-column type="index" width="50" fixed="left"> </el-table-column> -->
        <template v-for="(item, index) in tableConfig">
          <el-table-column
            :key="index"
            v-if="item.show"
            :width="item.width"
            :fixed="item.fixed ? item.fixed : false"
            :label="item.label"
            :align="item.align"
          >
            <template slot-scope="scope">
              <!-- 默认 -->
              <div v-if="!item.customLayout" class="default-column">
                <template v-if="item.id == 'status_name'">
                  <span :style="{ color: scope.row[item.id] == '已通过' ? item.spanColor : '#252632' }">
                    {{ scope.row[item.id] + (item.formatterStr ? item.formatterStr : "") }}
                  </span>
                </template>
                <template v-else>
                  {{ scope.row[item.id] + (item.formatterStr ? item.formatterStr : "") }}
                </template>
              </div>

              <!-- 自定义 -->
              <template v-if="item.customLayout">
                <template v-if="item.id == 'account'">
                  <div class="account-column">
                    <img :src="scope.row.avatar" alt="" class="account-img" />
                    <span>{{ scope.row.nickname }}</span>
                  </div>
                </template>

                <template v-if="item.id == 'title-time'">
                  <div class="title-time-column">
                    <img :src="scope.row.cover_url" alt="" class="title-time-img" />
                    <div class="title-time-info">
                      <el-popover
                        popper-class="black-popover"
                        trigger="hover"
                        :content="scope.row.title"
                        width="180"
                        :disabled="checkOverflowArr[scope.$index]"
                      >
                        <span class="popover-span ellipses" slot="reference" :ref="`textElement`">{{ scope.row.title }}</span>
                      </el-popover>

                      <div class="info-time">
                        <span>{{ scope.row.video_create_time }}</span>
                        <i class="icon-label" v-if="scope.row.media_type == '2'">图文</i>
                        <i class="icon-label" v-if="scope.row.media_type == '4'">视频</i>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="item.id == 'operation'">
                  <div class="operation">
                    <el-button type="text" @click="getItemDetail(scope.row.id)">分析详情</el-button>
                  </div>
                </template>
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>

    <div class="medio-load">
      <!-- <span v-if="!loadMore" style="cursor: auto">没有更多作品</span> -->
      <!-- <span v-if="loadMore" @click="loadMoreData">点击加载更多</span> -->
      <el-pagination
        layout="prev, pager, next"
        :current-page="params.page"
        :total="itemTotal"
        :page-size="params.limit"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostsList",
  data() {
    return {
      tableConfig: [
        {
          id: "account",
          label: "账号名称",
          show: true,
          customLayout: true,
          fixed: "left",
          width: 300,
          align: "left",
        },
        {
          id: "title-time",
          label: "作品名称 | 发布时间",
          show: true,
          customLayout: true,
          width: 350,
          fixed: "left",
          align: "left",
        },
        {
          id: "status_name",
          label: "状态",
          show: true,
          width: 120,
          customLayout: false,
          align: "center",
          spanColor: "#42c574",
        },
        {
          id: "play_count",
          label: "播放量",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "avg_play_duration",
          label: "平均播放时长",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
          formatterStr: "秒",
        },
        {
          id: "digg_count",
          label: "点赞量",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "share_count",
          label: "分享量",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "comment_count",
          label: "评论数",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "operation",
          label: "操作",
          show: true,
          customLayout: true,
          fixed: "right",
          // width: 120,
          align: "center",
        },
      ],
      itemTotal: 0,
      itemList: [], // 作品列表
      params: {
        id: "",
        date: "",
        paging: "1",
        page: 1,
        limit: 10,
      },
      checkOverflowArr: [],
      // loadMore: true,
    };
  },
  props: {
    filterAccounts: { type: Array, required: true },
    filterTime: { type: Array, required: true },
    allAccounts: { type: Array, required: true },
  },
  watch: {
    filterAccounts: {
      handler(newval) {
        this.$nextTick(() => this.getContentItemList());
      },
      deep: true,
      immediate: false,
    },
    filterTime: {
      handler(newval) {
        this.$nextTick(() => this.getContentItemList());
      },
      deep: true,
      immediate: false,
    },
    allAccounts: {
      handler(newval) {
        this.$nextTick(() => this.getContentItemList());
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getContentItemList(isAppend = false) {
      this.params.id = this.filterAccounts.toString();
      this.params.date = this.filterTime.toString();
      // this.params.page = isAppend ? this.params.page + 1 : 1;
      if (this.params.id == "" || this.params.date == "") {
        this.itemList = [];
        this.itemTotal = 0;
        return;
      }

      this.$httpStudent.axiosGetBy(this.$api.dataCenterContentList, this.params, (res) => {
        if (res.code == 200) {
          this.itemList = res.data.list;
          this.itemTotal = res.data.total;

          this.$nextTick(() => this.checkOverflow());
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    // 检测文字是否超出
    checkOverflow(index) {
      const elements = this.$refs[`textElement`];
      if (!elements) return;

      // 设置了fixed，el-table会重复渲染多次表格
      const filterElements = elements.filter((item) => {
        let hasClass =
          item.offsetParent &&
          item.offsetParent.offsetParent &&
          item.offsetParent.offsetParent.className &&
          item.offsetParent.offsetParent.className.includes("el-table__fixed-body-wrapper");
        return !hasClass;

        // let parentClass = item.offsetParent?.offsetParent?.className || "";
        // return !parentClass.includes("el-table__fixed-body-wrapper");
      });
      this.checkOverflowArr = [];
      filterElements.forEach((item) => {
        this.checkOverflowArr.push(item.scrollWidth - item.clientWidth > 0 ? false : true);
      });
    },

    getItemDetail(id) {
      const routeData = this.$router.resolve({
        path: "/student/dataCenter/ContentDetail",
        query: { id, },
      });
      // this.$router.push({
      //   path: "/student/dataCenter/ContentDetail",
      //   query: { id, accountIds: this.allAccounts.toString(), date: this.filterTime.toString() },
      // });
      // 新开页面
      window.open(routeData.href, "_blank");
    },

    handleCurrentChange(val) {
      this.params.page = val;
      this.getContentItemList();
    },

    exportData() {
      let params = {
        id: this.filterAccounts.toString(),
        date: this.filterTime.toString(),
        token: localStorage.getItem("studentToken"),
        paging: "2",
        limit: this.params.page * this.params.limit,
      };

      // if (this.filterAccounts.length == 0) params.id = this.allAccounts.toString();
      if (params.id == "" || params.date == "") return;

      this.$httpStudent.axiosSetTypeGet(this.$api.dataCenterContentListExport, params, "arraybuffer", (res) => {
        const blob = new Blob([res], { type: ".xlsx" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "投稿列表数据.xlsx"; // 设置文件名
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },

    // loadMoreData() {
    //   this.getContentItemList(true);
    // },

    refresh() {
      // console.log("refresh");
      this.params.page = 1;

      this.getContentItemList();
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.posts-list {
  padding-block: 24px;

  .ellipses {
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 超出容器部分隐藏 */
    text-overflow: ellipsis; /* 超出部分用省略号代替 */
  }

  .refresh {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(37, 38, 50, 0.6);
    line-height: 16px;
    cursor: pointer;
  }

  .item {
    margin-bottom: 16px;
  }

  .item-header {
    &_operation {
      ::v-deep .el-button {
        padding: 6px 12px;
        background-color: #f2f2f4;
        border: none;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #777880;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .medio-load {
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;

    span {
      font-weight: 400;
      font-size: 16px;
      color: #888888;
      line-height: 19px;
      cursor: pointer;
    }
  }

  .container-box {
    min-height: calc(100vh - 400px);

    .account-column {
      display: flex;
      align-items: center;
      gap: 3px;

      .account-img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
      }

      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #252632;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    .title-time-column {
      display: flex;
      align-items: center;
      gap: 5px;

      .title-time-img {
        width: 40px;
        height: 60px;
        object-fit: cover;
        border-radius: 4px;
      }

      .title-time-info {
        display: flex;
        flex-direction: column;

        span.popover-span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #252632;
          text-align: left;
          font-style: normal;
          text-transform: none;
          cursor: pointer;
          display: inline-block;
          width: 250px;
        }

        .info-time {
          span,
          i {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            max-width: 41.5%;
            color: rgba(37, 38, 50, 0.6);
            text-align: left;
            font-style: normal;
          }

          & .icon-label {
            margin-left: 5px;
            color: #252632;
            padding: 1px 3px;
            border: 1px solid #f2f2f4;
          }
        }
      }
    }

    ::v-deep .el-scrollbar .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    ::v-deep(.el-scrollbar__bar.is-horizontal) {
      height: 0px;
    }

    ::v-deep(.el-scrollbar__bar.is-vertical) {
      width: 0px;
    }

    ::v-deep(.el-table__fixed-right::before),
    ::v-deep(.el-table__fixed::before) {
      background-color: transparent;
    }

    ::v-deep(.el-table td.el-table__cell),
    ::v-deep(.el-table th.el-table__cell.is-leaf) {
      border-bottom: none;
    }

    /* 自定义横向滚动条样式 */
    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar) {
      height: 10px;
    }

    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar-track) {
      // background: black;
      height: 10px;
    }

    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
      display: none;
      background: #dadbdd;
      border-radius: 5px;

      &:hover {
        display: block;
      }
    }

    ::v-deep(.el-table:hover .el-table__body-wrapper::-webkit-scrollbar-thumb) {
      display: block;
    }
  }
}
</style>
